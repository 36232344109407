























































import { prefix } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import  { getPassengerFlowDistribute }  from '@/services/api';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import { getMapCenter, initMap, drawMall, clearMall, processDataByStartEnd, zoomUp, zoomDown } from '@/components/map/map';
import { Grid } from '@/components/map/grid';
import gradientCard from '@/components/GradientCard/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import { getContainer, getMax, getMin, processObj } from '@/components';

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
@Component({
  components: {
    spinCard,
    rankFilter,
    rankTable,
    gradientCard,
  },
})


export default class Distributed extends Vue {
  prefix: string = prefix;
  spinShow = true;
  mallMarker!: any;
  tableWh = 600;
  table: any= [];
  dataTable: any = [];
  res: any;
  data: any= [];
  map: any;
  filterValue: number[]=[0, 100];
  duration = 'xm';
  mall_id = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  dates = this.$store.state.mall.dates;
  boundary = this.$store.state.mall.boundary;
  center = this.$store.state.mall.center;
  params!: any;
  rowActive = 0;
  initZom = 13;
  step = 0.01;
  max = 100;
  min = 0;
  gridColor = ['rgba(43,50,153, .7)', 'rgba(46,63,176, .7)', 'rgba(50,85,199, .7)', 'rgba(49,104,222, 7)', 'rgba(43,115,224, .7)', 'rgba(41,132,230, .7)', 'rgba(41,145,230, .7)', 'rgba(46,178,255, .7)', 'rgba(74,195,255, .7)'].reverse();
  gridColor2 = ['rgba(255,255,255,.1)'];
  grid: any;
  title = '本市覆盖排名';
  type = 0;
  text = '1平方公里';
  singleColor = 'blue';
  titleDisplay = true;
  gradientWidth = 22;
  gradientList = [
    {
      id: 1,
      color: this.gridColor[0],
      width: this.gradientWidth,
    },
    {
      id: 2,
      color: this.gridColor[1],
      width: this.gradientWidth,
    },
    {
      id: 3,
      color: this.gridColor[2],
      width: this.gradientWidth,
    },
    {
      id: 4,
      color: this.gridColor[3],
      width: this.gradientWidth,
    },
  ];
  gridColorObj1 = {
    color: this.gridColor,
    top0Color: '',
    top1Color: '',
    strokeStyle: 'rgba(255,255,255,.6)',
    lineWidth: 1,
    textColor: '#ffffff',
    mColor: 'rgba(64, 200, 245, .6)',
    nColor: 'rgba(0, 98, 255, .6)',
  };
  color = 'rgba(255,255,255,.1)';
  gridColorObj2 = {
    color: this.gridColor2,
    top0Color: '',
    top1Color: '',
    strokeStyle: 'rgba(80,128,215,1)',
    lineWidth: 2,
    textColor: 'black',
    mColor: this.gridColor2[0],
    nColor: this.gridColor2[0],
  };
  gridColorObj = this.gridColorObj1;
  mapHeight = '0';
  gridType = 0;
  gridClickType = 0;
  gridFunctionStatus = 1;
  gridFunctionOffStatus = 0;
  compete_mall_id = 0;
  competeGridData: any = [];
  MGridData: any = [];
  nMallMarker: any;
  $beaconAction: any;
  asyncData = {
    dates: '',
    mArea: 0,
    mSum: 0,
    nArea: 0,
    nTotal: 0,
    nSum: 0,
  };

  mounted(): void {
    this.boundary = this.$store.state.mall.boundary;
    this.center = this.$store.state.mall.center;
    this.mapHeight = `${getContainer()}px`;
    this.map = initMap('map-container', this.initZom, this.center);
  }

  pageHandlers(): void{
    this.spinShow = true;
    setTimeout(() => {
      this.pageHandler(this.gridType, this.gridColorObj);
    }, 0);
  }

  getTableByType(): any {
    const table = [
      {
        title: '竞争客流排名',
        key: 'rank',
        sortable: true,
        align: 'center',
      },
      {
        title: '竞争客流',
        key: 'num',
        sortable: true,
        align: 'center',
      },
      {
        title: '主场客流数',
        key: 'm_passenger',
        sortable: true,
        align: 'center',
      },
      {
        title: '竞争客流占比(%)',
        key: 'n_m_percent',
        sortable: true,
        align: 'center',
      },
    ];
    return table;
  }

  buriedPointHandler(buriedPoint: string, params?: any): void{
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async pageHandler(type: number, gridColorObj?: any): Promise<void> {
    const { mall } = this.$store.state;
    const { mallId, boundary, center, duration, date, dates, competedModelObj } = mall;
    const { nMallId, nCenterGcj } = competedModelObj;
    this.mall_id = mallId;
    this.boundary = boundary;
    this.center = center;
    this.duration = duration;
    this.date = date;
    this.dates = dates;
    this.compete_mall_id = nMallId;
    let mArea: any = 0;
    let mSum: any = 0;
    let nSum: any = 0;
    let nArea: any = 0;
    let nTotal: any = 0;
    if (type === 1) {
      const data = processDataByStartEnd(this.filterValue[0], this.filterValue[1], this.competeGridData);
      this.data = data.concat(this.MGridData);
      this.dataTable = data;
    } else {
      if (this.center) {
        const center = getMapCenter(this.center);
        this.map.setCenter(center);
        this.map.panBy(198, 0);
        clearMall(this.mallMarker);
        this.mallMarker = drawMall(this.map, center);
      }
      if (nCenterGcj) {
        const center = getMapCenter(nCenterGcj);
        clearMall(this.nMallMarker);
        this.nMallMarker = drawMall(this.map, center, 1);
      }
      if (this.date) {
        const date = this.date.split('-');
        this.date = `${date[0]}-${date[1]}`;
      }
      this.params = {
        mall_id: this.mall_id,
        date: this.date,
        duration: this.duration,
        compete_mall_id: this.compete_mall_id,
        percent_start: 0,
        percent_end: 100,
        type: 1,
      };
      this.table = this.getTableByType();
      this.res = await getPassengerFlowDistribute(this.params);
      let { m_grid_data: mGridData,
        n_grid_customerAry: competeGridData,
        m_grid_area: mGridArea,
        m_grid_totalpassenger: mSum1,
        n_grid_totalpassenger: nSum1,
        n_grid_area: nArea1,
        n_grid_totalnum: nTotal1,
      } = this.res.data;

      mGridData = mGridData ? mGridData : [];
      competeGridData = competeGridData ? competeGridData : [];
      mGridArea = mGridArea ? mGridArea : 0;
      mSum1 = mSum1 ? mSum1 : 0;
      nSum1 = nSum1 ? nSum1 : 0;
      nArea1 = nArea1 ? nArea1 : 0;
      nTotal1 = nTotal1 ? nTotal1 : 0;
      mArea = mGridArea;
      mSum = mSum1;
      nSum = nSum1;
      nArea = nArea1;
      nTotal = nTotal1;

      const gridObj1 = processObj(mGridData, 'grid_id');
      const gridObj2 = processObj(competeGridData, 'grid_id');
      const gridUnqiue = [];
      // eslint-disable-next-line
      for (const key in gridObj1) {
        if (Object.prototype.hasOwnProperty.call(gridObj1, key)
        && !Object.prototype.hasOwnProperty.call(gridObj2, key)
        ) {
          const el = gridObj1[key];
          el.color = gridColorObj.nColor;
          gridUnqiue.push(el);
        }
      }
      competeGridData.forEach((item: any, index: number) => {
        competeGridData[index].color = gridColorObj.mColor;
      });
      this.competeGridData = competeGridData;
      this.MGridData = gridUnqiue;
      this.data = competeGridData.concat(gridUnqiue);
      this.dataTable = competeGridData;
      this.max = getMax(this.data, 'num');
      this.min = getMin(this.data, 'num');
    }
    this.asyncData = {
      dates: this.dates,
      mArea,
      mSum,
      nArea,
      nTotal,
      nSum,
    };
    this.data.forEach((item: any, index: number) => {
      (this.data[index] as any).index = index;
    });
    this.grid = new (Grid as any)({
      pageType: 'distributed',
      map: this.map,
      data: this.data,
      color: gridColorObj.color,
      weightFiled: 'num',
      weightType: 1,
      rankFiled: 'rank',
      top0Color: gridColorObj.top0Color,
      top1Color: gridColorObj.top1Color,
      strokeStyle: gridColorObj.strokeStyle,
      lineWidth: gridColorObj.lineWidth,
      textColor: gridColorObj.textColor,
    });
    this.$nextTick(() => {
      if (this.data.length !== 0) {
        const item = this.data[0];
        this.rowActive = item.rank;
        (this.$refs.tables as any).tableClickHandler(item, 0);
      }
    });
    this.grid.gridClick((item: any) => {
      const { rank } = item;
      this.rowActive = rank;
      (this.$refs.tables as any).tableClickHandler(item, 1);
    });
    this.grid.gridMove();

    this.spinShow = false;
  }

  @Watch('$store.state.mall.duration', {})
  changeMall1(): void {
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers();
  }

  @Watch('$store.state.mall.date', {})
  changeMall2(): void {
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers();
  }

  @Watch('$store.state.mall.competedModelObj.nMallId', { immediate: true })
  changeCompetedModelObj(): void {
    this.gridColorObj = this.gridColorObj1;
    this.gridType = 0;
    this.pageHandlers();
  }

  sliderChange(val: number[]): void {
    // 进度条数据变化返回
    this.filterValue = val;
    // pageConfig.setFilterValue(val);
    this.gridType = 1;
    this.pageHandlers();
    this.buriedPointHandler('competition_rank_click');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  rowClick(val: any): void{
    // 列表点击事件返回数据
    if (this.rowActive === val.index) {
      return;
    }
    this.rowActive = val.index;
    const center = this.grid.getGridCenter(val);
    this.grid.polygonHandler(val);
    const { lat, lng } = center;
    const centers = new (window as any).qq.maps.LatLng(lat, lng);
    this.map.setCenter(centers);
    this.map.panBy(198, 0);
    // this.map.setZoom(this.initZom);
  }
  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  mallPostionClickHandler(): void{
    const center = getMapCenter(this.center);
    this.map.panTo(center);
    this.map.panBy(198, 0);
  }

  gridFunctionClickHandler(): void{
    // this.grid.destroy();
    if (this.gridClickType === 0) {
      this.gridFunctionStatus = 0;
      this.gridFunctionOffStatus = 1;
      this.gridClickType = 1;
      this.gridColorObj = this.gridColorObj2;
    } else {
      this.gridFunctionStatus = 1;
      this.gridFunctionOffStatus = 0;
      this.gridClickType = 0;
      this.gridColorObj = this.gridColorObj1;
    }
    const data: any = [];
    this.competeGridData.forEach((item: any) => {
      // eslint-disable-next-line no-param-reassign
      item.color = this.gridColorObj.mColor;
      data.push(item);
    });
    this.data = data.concat(this.MGridData);
    this.grid = new (Grid as any)({
      pageType: 'distributed',
      map: this.map,
      data: this.data,
      color: this.gridColorObj.color,
      weightFiled: 'num',
      weightType: 1,
      rankFiled: 'rank',
      top0Color: this.gridColorObj.top0Color,
      top1Color: this.gridColorObj.top1Color,
      strokeStyle: this.gridColorObj.strokeStyle,
      lineWidth: this.gridColorObj.lineWidth,
      textColor: this.gridColorObj.textColor,
    });
    this.grid.gridClick((item: any) => {
      const { rank } = item;
      this.rowActive = rank;
      (this.$refs.tables as any).tableClickHandler(item, 1);
    });
    this.grid.gridMove();
    const item = this.data[this.rowActive];
    setTimeout(() => {
      this.grid.polygonHandler(item);
    }, 0);
  }
}
